import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { Oferta, RequestOffers } from '../models/oferta';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "ripley-banco-dev";
  private BASE_URL: string = HelperService.buildRequestURL(environment.ripleyEndPointAPI);
  private BASE_URL_WEB: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getOffers(request: RequestOffers): Observable<Oferta> {
    return this.httpClient.post<Oferta>(`${this.REQUEST_URL}/v1.0/customer-offer/customer-offer-procedure/offers`, request);
  }

  getOffersNew(docType: string, docNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('DocumentType', docType);
    params = params.append('DocumentNumber', docNumber);
    return this.httpClient.get<any>(`${this.BASE_URL_WEB}/integration/ofertas`,{ params: params});
  }

  exitstOffers(docType: string, docNumber: string, solId:string): Observable<any> {
    let params = new HttpParams();
    params = params.append('DocumentType', docType);
    params = params.append('DocumentNumber', docNumber);
    params = params.append('SolId', solId);
    return this.httpClient.get<any>(`${this.BASE_URL_WEB}/integration/ValidateOffer`,{ params: params});
  }

  existsAccount(solId:string, userId:string): Observable<{ isValid: boolean, message:string }> {
    let params = new HttpParams();
    params = params.append('SolId', solId);
    params = params.append('UserId', userId);
    return this.httpClient.get<any>(`${this.BASE_URL_WEB}/integration/ValidateAccount`,{ params: params});
  }

}
