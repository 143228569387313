import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Rol, SalesChannelModel, SalesSubChannelModel } from '../models/rol';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class RolService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private POST_ENDPOINT: string = "Rol";
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    getRoles() {
        return this.httpClient.get<Rol[]>(`${this.REQUEST_URL}/GetRoles`);
    }

    getSalesChannel() {
        return this.httpClient.get<SalesChannelModel[]>(`${this.REQUEST_URL}/GetSalesChannel`);
    }

    getSalesSubChannel() {
        return this.httpClient.get<SalesSubChannelModel[]>(`${this.REQUEST_URL}/Channels/Subchannels`);
    }
}
