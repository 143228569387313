export class Entidades {
  guid: string;
  solId: string;
  entidadId: number;
  entCodigo: string;
  entNombre: string;
  entCuenta: string;
  entMoneda: number;
  entMonto: number;
  entProducto: string;
  estadoAccion: number;
  files: Array<Rutas> = [];
}
export class Rutas {
  rutaId: string;
  name: string;
  entidadId: number;
  estadoAccion: number;
  fileBase64: string;
  type: string;
  estadoAccionAnterior: number;
}
export class EntidadesUpdate {
  solId: string;
  listEntidades: Array<Entidades> = [];
}
