import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Simular, Cuota, Politica } from '../models/simular';

@Injectable({
  providedIn: 'root'
})
export class DummisService {

  constructor(private httpClient: HttpClient) { }

  private POST_ENDPOINT: string = "Dummis";
  private POST_ENDPOINTSIM: string = "Simulator";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;
  private REQUEST_URL_SIM: string = `${this.BASE_URL}/${this.POST_ENDPOINTSIM}`;


  Simular(simular: Simular): Observable<Cuota> {
      return this.httpClient.post<Cuota>(`${this.REQUEST_URL}/Simulador`, simular);
  }

  ListSimular(simular: Simular): Observable<Simular> {
    //return this.httpClient.post<Simular>(`${this.REQUEST_URL}/ListSimulador`, simular);
    return this.httpClient.post<Simular>(`${this.REQUEST_URL_SIM}/GetSimulacion`, simular);
}

  ValidarPolitica(simular: Simular): Observable<Politica>{
    return this.httpClient.post<Politica>(`${this.REQUEST_URL}/ValidarPolitica`, simular);
  }

  
}
