import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {HelperService} from '../../shared/services/helper.service';
import {environment} from '../../../environments/environment';
import {
    ActiveProduct,
    CardViewModel,
    ClientReniec,
    flagsServAsoc,
    ResponseConsultTCProducts,
    ResponseGetClientT24,
    RqActProd,
} from '../../models/client';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CreditCardService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    private POST_ENDPOINT: string = 'Client';
    private BASE_URL: string = HelperService.buildRequestURL(environment.msProductApi);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    getHistoricalList(contractCode: string, initialRegister:string, finalRegister:string): Observable<any>{
        const httpParams = new HttpParams({
            fromObject: {contractCode, initialRegister, finalRegister}
        });
        return this.httpClient.get<CardViewModel[]>(`${this.REQUEST_URL}/GetHistoricalList`, {params: httpParams});

        // const response: CardViewModel[] =  [
        //   {
        //     agency: 'Lima Central',
        //     codAgency: 'LC01',
        //     numberAccount: '001234567890',
        //     registerDate: '2024-01-01',
        //     registerHour: '12:00 PM',
        //     typeSend: 'Envio Fisico',
        //     typeModality: 'Presencial',
        //     numberCard: '4111111111111111',
        //     description: 'Tarjeta de Crédito Visa',
        //     channel: 'Online',
        //     userChannel: 'jperez',
        //     userHost: '192.168.1.10'
        //   },
        //   {
        //     agency: 'San Isidro',
        //     codAgency: 'SI02',
        //     numberAccount: '009876543210',
        //     registerDate: '2024-02-15',
        //     registerHour: '3:45 PM',
        //     typeSend: 'Envio Electrónico',
        //     typeModality: 'Virtual',
        //     numberCard: '4222222222222222',
        //     description: 'Tarjeta de Crédito MasterCard',
        //     channel: 'App',
        //     userChannel: 'jperez',
        //     userHost: '192.168.1.11'
        //   }
        // ]

        // return of(response);


    }

    getProductsTC(typeDocument: string, documentNumber: string): Observable<ResponseConsultTCProducts> {
      const httpParams = new HttpParams({
          fromObject: {docType: typeDocument, documentNumber: documentNumber}
      });
      return this.httpClient.get<ResponseConsultTCProducts>(`${this.REQUEST_URL}/ProductsTC`, {params: httpParams});
      // const response: ResponseConsultTCProducts = {
      //   idClientT24: 'C123456',
      //   fullName: 'Juan Perez',
      //   email: 'juan.perez@example.com',
      //   cards: [
      //     {
      //       agency: 'Lima Central',
      //       codAgency: 'LC01',
      //       numberAccount: '001234567890',
      //       registerDate: '2024-01-01',
      //       registerHour: '12:00 PM',
      //       typeSend: 'Envio Fisico',
      //       typeModality: 'Presencial',
      //       numberCard: '4111111111111111',
      //       description: 'Tarjeta de Crédito Visa',
      //       channel: 'Online',
      //       userChannel: 'jperez',
      //       userHost: '192.168.1.10'
      //     },
      //     {
      //       agency: 'San Isidro',
      //       codAgency: 'SI02',
      //       numberAccount: '009876543210',
      //       registerDate: '2024-02-15',
      //       registerHour: '3:45 PM',
      //       typeSend: 'Envio Electrónico',
      //       typeModality: 'Virtual',
      //       numberCard: '4222222222222222',
      //       description: 'Tarjeta de Crédito MasterCard',
      //       channel: 'App',
      //       userChannel: 'jperez',
      //       userHost: '192.168.1.11'
      //     }
      //   ]
      // };

      // return of(response);
  }
  getActiveProducts(idcli:string,usuario:string) {
    const model = {
        userid :usuario,
        idclientT24 :idcli
    }
    return this.httpClient.post<ActiveProduct>(`${this.REQUEST_URL}/GetActiveProduct`, model);
  }

  getRestrictions(nroTarj:string,idSolicitud:string,idUsuario:string){
    const model = {
        cardNumber : nroTarj,
        userId : idUsuario,
        solId : idSolicitud
    }
    return this.httpClient.post<flagsServAsoc>(`${this.REQUEST_URL}/GetRestrictions`, model);
  }

  getDispEfect(nroTarj:string,idSolicitud:string,idUsuario:string){
    const model = {
        cardNumber : nroTarj,
        userId : idUsuario,
        solId : idSolicitud
    }
    return this.httpClient.post<flagsServAsoc>(`${this.REQUEST_URL}/GetDisEfect`, model);
  }
}
