export class AuditoriaGeneral {
    solId: string;
    documentType: number;
    documentNumber: string;
    type: number;
    decimal01: number;
    decimal02: number;
    decimal03: number;
    description01: string;
    description02: string;
    description03: string;
}

export class AuditoriaGeneralFilter {
    startDate: string;
    endDate: string;
    solId: string;
    documentNumber: string;
    typeId: number;
    documentTypeId: number;
}

export class AuditoriaGeneralTipo {
    id: number;
    name: string;
}
