import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AuditoriaCredito, AuditoriaCreditoCliente, AuditoriaCreditoHistorial, MotivoBloqueo, Respuesta, TipoContacto } from '../models/credit-card';

@Injectable({
    providedIn: 'root'
})
export class CreditCardService {

    constructor(private httpClient: HttpClient) {
    }

    private POST_ENDPOINT: string = 'CreditCard';
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    // #region MotivoBloqueo
    getMotivoBloqueo(Id: string): Observable<MotivoBloqueo> {
        return this.httpClient.get<MotivoBloqueo>(`${this.REQUEST_URL}/MotivoBloqueo/Get`, { params: { Id: Id } });
    }
    getAllMotivoBloqueo(): Observable<MotivoBloqueo[]> {
        return this.httpClient.get<MotivoBloqueo[]>(`${this.REQUEST_URL}/MotivoBloqueo/GetAll`);
    }
    createMotivoBloqueo(model: MotivoBloqueo): Observable<number> {
        let params = {
            MotBloCodigo: model.motBloCodigo,
            MotBloNombre: model.motBloNombre,
            TipoBloqueoId: model.tipoBloqueoId,
            MotBloOrden: model.motBloOrden,
            MotBloEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/MotivoBloqueo/Create`, params);
    }
    updateMotivoBloqueo(model: MotivoBloqueo): Observable<number> {
        let params = {
            MotivoBloqueoId: model.motivoBloqueoId,
            MotBloCodigo: model.motBloCodigo,
            MotBloNombre: model.motBloNombre,
            TipoBloqueoId: model.tipoBloqueoId,
            MotBloOrden: model.motBloOrden,
            MotBloEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/MotivoBloqueo/Update`, params);
    }
    deleteMotivoBloqueo(motivoBloqueoId:number): Observable<number> {
        return this.httpClient.post<number>(`${this.REQUEST_URL}/MotivoBloqueo/Delete`, { motivoBloqueoId });
    }
    // #endregion

    //#region TipoContacto
    getTipoContacto(Id: string): Observable<TipoContacto> {
        return this.httpClient.get<TipoContacto>(`${this.REQUEST_URL}/TipoContacto/Get`, { params: { Id: Id } });
    }
    getAllTipoContacto(): Observable<TipoContacto[]> {
        return this.httpClient.get<TipoContacto[]>(`${this.REQUEST_URL}/TipoContacto/GetAll`);
    }
    createTipoContacto(model: TipoContacto): Observable<number> {
        let params = {
            tipConCodigo: model.tipConCodigo,
            tipConNombre: model.tipConNombre,
            motivoBloqueoId: model.motivoBloqueoId,
            tipConEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/TipoContacto/Create`, params);
    }
    updateTipoContacto(model: TipoContacto): Observable<number> {
        let params = {
            tipoContactoId: model.tipoContactoId,
            tipConCodigo: model.tipConCodigo,
            tipConNombre: model.tipConNombre,
            motivoBloqueoId: model.motivoBloqueoId,
            tipConEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/TipoContacto/Update`, params);
    }
    deleteTipoContacto(tipoContactoId:number): Observable<number> {
        return this.httpClient.post<number>(`${this.REQUEST_URL}/TipoContacto/Delete`, {tipoContactoId});
    }
    //#endregion
    //#region Respuesta
    getRespuesta(Id: string): Observable<Respuesta> {
        return this.httpClient.get<Respuesta>(`${this.REQUEST_URL}/Respuesta/Get`, { params: { Id: Id } });
    }
    getAllRespuesta(): Observable<Respuesta[]> {
        return this.httpClient.get<Respuesta[]>(`${this.REQUEST_URL}/Respuesta/GetAll`);
    }
    createRespuesta(model: Respuesta): Observable<number> {
        let params = {
            resCodigo: model.resCodigo,
            resNombre: model.resNombre,
            tipoContactoId: model.tipoContactoId,
            tipoRespuestaId: model.tipoRespuestaId,
            resEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/Respuesta/Create`, params);
    }
    updateRespuesta(model: Respuesta): Observable<number> {
        let params = {
            respuestaId:model.respuestaId,
            resCodigo: model.resCodigo,
            resNombre: model.resNombre,
            tipoContactoId: model.tipoContactoId,
            tipoRespuestaId: model.tipoRespuestaId,
            resEstado: 1
        }
        return this.httpClient.post<number>(`${this.REQUEST_URL}/Respuesta/Update`, params);
    }
    deleteRespuesta(respuestaId:number): Observable<number> {
        return this.httpClient.post<number>(`${this.REQUEST_URL}/Respuesta/Delete`, { respuestaId });
    }
    //#endregion
    //#region AcuditoriaCredito
    // createAuditoriaCredito(model: AuditoriaCredito): Observable<number> {
    //     let params = {
    //         CliNroDocumento: model.cliNroDocumento,
    //         RespuestaId : model.respuestaId,
    //         TipoContactoId : model.tipoContactoId,
    //         MotivoObservacionArray :model.motivoObservacionArray,
    //         Observacion :model.observacion,
    //         UsuarioInsert: model.usuarioInsert,
    //         estado:model.estado
    //     }
    //     return this.httpClient.post<number>(`${this.REQUEST_URL}/AuditoriaCredito/Create`, params);
    // }
    // updateAuditoriaCredito(model: AuditoriaCredito): Observable<number> {
    //     let params = {
    //         AuditoriaCreditoId : model.auditoriaCreditoId,
    //         RespuestaId : model.respuestaId,
    //         TipoContactoId : model.tipoContactoId,
    //         MotivoObservacionArray :model.motivoObservacionArray,
    //         Observacion :model.observacion,
    //         UsuarioUpdate : model.usuarioUpdate
    //     }
    //     return this.httpClient.post<number>(`${this.REQUEST_URL}/AuditoriaCredito/Update`, params);
    // }
    getAuditoriaCredito(NroDocumento: string): Observable<AuditoriaCredito> {
        return this.httpClient.get<AuditoriaCredito>(`${this.REQUEST_URL}/AuditoriaCredito/Get`, { params: {NroDocumento } });
    }
    getAllAuditoriaCredito(cliNroDocumento:string): Observable<AuditoriaCredito[]> {
        return this.httpClient.get<AuditoriaCredito[]>(`${this.REQUEST_URL}/AuditoriaCredito/GetAll`, {params:{cliNroDocumento}});
    }
    getAuditoriaCreditoHistorial(id:string): Observable<AuditoriaCreditoHistorial> {
        return this.httpClient.get<AuditoriaCreditoHistorial>(`${this.REQUEST_URL}/AuditoriaCreditoHistorial/Get`, { params: { id } });
    }
    getAllAuditoriaCreditoHistorial(auditoriaCreditoId:string): Observable<AuditoriaCreditoHistorial[]> {
        return this.httpClient.get<AuditoriaCreditoHistorial[]>(`${this.REQUEST_URL}/AuditoriaCreditoHistorial/GetAll`, {params:{auditoriaCreditoId}});
    }
    getClienteCredito(NroDocumento: string): Observable<AuditoriaCreditoCliente> {
        return this.httpClient.get<AuditoriaCreditoCliente>(`${this.REQUEST_URL}/AuditoriaCredito/GetClienteCredito`, { params: { NroDocumento } });
    }
    processAuditoriaCredito(model:any): Observable<any> {
        return this.httpClient.post<number>(`${this.REQUEST_URL}/AuditoriaCredito/Process`, model);
    }
    //#endregion





}
