import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Client, CreateClient, UploadFile, FilesClient, FileDeleteClient } from '../models/client';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Empresa } from '../models/empresa';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private POST_ENDPOINT: string = "Enterprise";
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    createEmpresa(empresa: Empresa): Observable<Empresa> {
        return this.httpClient.post<Empresa>(`${this.REQUEST_URL}/CreateEmpresa`, empresa);
    }

    updateEmpresa(empresa: Empresa): Observable<Empresa> {
        return this.httpClient.put<Empresa>(`${this.REQUEST_URL}/UpdateEmpresa`, empresa);
    }
    getEmpresas(parameters: any) {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
        return this.httpClient.get<Empresa[]>(`${this.REQUEST_URL}/GetEmpresas`, { params: httpParams });
    }
}
